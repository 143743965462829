import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StrictMode, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { TooltipProvider } from './components/ui/tooltip';

const components = {
  AIChat: lazy(() => import('./sections/AIChat')),
  OppsPDF: lazy(() => import('./sections/OpportunityPdfProfile'))
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnWindowFocus: false,
      // refetchOnMount: false,
      // This is data that is currently NOT used in any query
      gcTime: 1000 * 60 * 30, // 30 minutes
      // This is data that is currently USED in a query on the page you are currently viewing.
      // Going outside of that page will start the gcTime counter
      // staleTime: 1000 * 60 * 30 // 30 minutes
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchOnMount: false
    }
  }
});

function fallbackRender({ error }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  );
}

document.querySelectorAll('[data-component]').forEach((el) => {
  const componentName = el.getAttribute('data-component');
  if (!componentName) return;

  const Component = components[componentName] ?? undefined;
  if (!Component) return;

  const props = JSON.parse(el.getAttribute('data-props') ?? '{}');

  el.innerHTML = '';
  createRoot(el).render(
    <StrictMode>
      <ErrorBoundary FallbackComponent={fallbackRender}>
        <QueryClientProvider client={queryClient}>
          <TooltipProvider>
            <Component {...props} />
          </TooltipProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </StrictMode>
  );
});
